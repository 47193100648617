import { useState, useEffect } from "react";
import { Layout, Row, Col, Avatar, Button, Tooltip, Switch, } from "antd";
import { CheckOutlined, CloseOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { clearLocalStorage, getFromLocal } from "../utils/cache";
import { redirect } from "../Routes/routing-constants";
import { Link, useLocation } from "react-router-dom";
import history from "../History/history";
import { actionDispatch, getVendors, setOrganization } from "../actions/Creators";
import { LOGOUT } from "../actions/Types";
import { USER_PROFILE_ROUTE } from "../Constants/routeNames";
import { isEmpty } from "../utils/isEmpty";
import Authorized from "../Role-Authorization/Authorized";
import OrganizationDropdown from "../Common/OrganizationDropdown";
import VendorDropdown from "../Common/VendorDropdown";
import Modal from "../Common/Modal";
import {
  ROLES_TRUPACTA_TEAM_VIEW,
  ROLES_HEADER_ORGANIZATION_VIEW,
  LOCAL_STORAGE_KEYS,
} from "../utils/constants";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { setVendor } from "../actions/Creators/Vendors";
import { changeTheme } from "../actions/Creators/common";

const { Header } = Layout;

const HeaderTop = ({ toggle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation()["pathname"];
  const user = useSelector((state) => state.auth.user);
  const selectedOrg = useSelector((state) => state.header.selectedOrganization);

  const vendors = useSelector((state) => state.vendor);
  const [selVendor, setSelVendor] = useState('ALL');
  const selectedVendor = useSelector((state) => state.header.selectedVendor);

  useEffect(() => {
    let organization = isEmpty(selectedOrg)
      ? user && user.organization
      : selectedOrg;
    dispatch(setOrganization(organization));

    dispatch(setVendor(0));
    dispatch(getVendors());
  }, []);

  useEffect(() => {
    const sv = vendors.vendors.find((v) => v.id == selectedVendor);
    setSelVendor(sv ? sv.title : 'ALL');
  }, [selectedVendor]);

  const logout = async () => {
    clearLocalStorage();
    dispatch(actionDispatch(LOGOUT));
    redirect("/login", location);
    history.push("/");
  };

  const saveSelectedOrgToLocal = (orgData) => {
    const data = {
      id: orgData.key,
      name: orgData.value,
    };
    dispatch(setOrganization(data));
  };

  return (
    <>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} handleOk={() => logout()} setParentClose={() => setIsModalOpen(false)}>
          Are you sure you want to logout?
        </Modal>
      )}
      <Header className="app-header">
        <Row align='middle'>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="app-main-logo">
            <Button
              type="primary"
              className="btn-menu-toggle"
              title={collapsed ? 'Expand' : 'Collapse'}
              onClick={() => {
                setCollapsed(!collapsed);
                toggle();
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>

            {/*    
            {!isEmpty(location.split("/")[1]) && (
              <Authorized roleList={ROLES_TRUPACTA_TEAM_VIEW}>
                <span className="org-name">{selectedOrg && selectedOrg.name}</span>
              </Authorized>
            )}
            <Authorized roleList={ROLES_HEADER_ORGANIZATION_VIEW}>
              <span className="org-name">{selectedOrg && selectedOrg.name}</span>
            </Authorized>

            {isEmpty(location.split('/')[1]) && (
              <Authorized roleList={ROLES_TRUPACTA_TEAM_VIEW}>
                <OrganizationDropdown setSelectedOrg={(option) => saveSelectedOrgToLocal(option)} />
              </Authorized>
            )}
            */}

            {isEmpty(location.split('/')[1]) && (
              <VendorDropdown
                vendor={vendors}
                getValue={(v) => {
                  dispatch(setVendor(v.key));
                }}
                addAll={true}
                id={0}
                defaultValue={selVendor}
                create={false}
              />
            )}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="app-login-user">
            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            <p className="label-login-user-name">
              <Link to={USER_PROFILE_ROUTE}>{user.first_name + ' ' + user.last_name}</Link>
            </p>
            <Tooltip title="Logout">
            <LogoutOutlined className="btn-logout" onClick={() => setIsModalOpen(true)} />
              {/* <span className="icon-log-out btn-logout" onClick={() => setIsModalOpen(true)}></span> */}
            </Tooltip>
            <Switch
              checkedChildren={<span class="icon-light-mood"></span>}
              unCheckedChildren={<span class="icon-dark-mode"></span>}
              checked={getFromLocal(LOCAL_STORAGE_KEYS.THEME, false) === 'dark-theme'}
              className="ml-1"
              onChange={(val) => {
                dispatch(changeTheme(val ? 'dark-theme' : 'light-theme'));
              }}
            />
            {/* <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked
              
            /> */}
          </Col>
        </Row>
      </Header>
    </>
  );
};
export default HeaderTop;
