import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "antd";
import { Link, useHistory } from "react-router-dom";
import VendorDropdown from "../../Common/VendorDropdown";
import { useSelector } from "react-redux";
import DollarSign from "../../assets/images/dollar.svg";
import DollarSign_white from "../../assets/images/dollar-white.svg";
import ArrowUp from "../../assets/images/Polygon.svg";
import ArrowDown from "../../assets/images/down.svg";
import Information from "../../assets/images/information-icon.svg";
import { Column, Pie, Bar, Bullet } from "@ant-design/charts";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getNonRebatableSitesAPI, getRebatableSitesAPI, getRebateTierAPI, getRemainingDaysAPI, getYtdPurchaseAPI, getAllRebatesTierAPI, getAllRebatesTierRowAPI, getSitesGeneratingRebatesAPI } from "../../actions/Creators/Rebates";
import { operationalRebates } from "./data";
import "./rebates.scss";
import { amount, findByItem, groupDataByYear, percentageChange } from "../../utils/helpers";
import { VIEW_PO_COMPLIANCE_ROUTE } from "../../Constants/routeNames";
import { Column as ColumnStacked } from "@ant-design/plots";
import RebateTier from "../Graphs/Rebate-Tier";

const Index = () => {
  const history = useHistory();

  // new Intl.NumberFormat("en-US").format()
  const [form] = Form.useForm();
  const vendor = useSelector((state) => state.vendor);

  const [ytdPurchase, setYtdPurchase] = useState();
  const [rebateTier, setRebateTier] = useState();
  const [allRebateTiers, setAllRebateTiers] = useState();
  const [spendTierRow, setSpendTierRow] = useState();
  const [remainingDays, setRemainingDays] = useState();
  const [rebatableSites, setRebatableSites] = useState();
  const [nonRebatableSites, setNonRebatableSites] = useState();
  const [annualIncentives, setAnnualIncentives] = useState([]);
  const [sitesGrowth, setSitesGrowth] = useState([]);
  const [operationalRebatePercentage, setOperationalRebatePercentage] = useState(0);

  const operationalRebatesData = operationalRebates
    .filter((obj) => obj.Spend2023 !== undefined)
    .reduce(
      (acc, item) => {
        acc.totalSpend2023 += item?.Spend2023 || 0;
        acc.totalSpend2022 += item?.Spend2022 || 0;
        acc.totalIncentiveAmount += item["Incentive Amount"] || 0;
        return acc;
      },
      { totalSpend2023: 0, totalIncentiveAmount: 0, totalSpend2022: 0 }
    );

  // year 2023 only
  const sitesGeneratingRebates = operationalRebates
    // .filter((obj) => obj.Spend2023 !== undefined)
    .reduce(
      (acc, item) => {
        if (item["Growth/De-growth"] === "De-growth") {
          acc.nonGrowthIncentives += 1;
          acc.nonGrowthSites.push(item);
        } else {
          acc.growthIncentives += 1;
          acc.growthSites.push(item);
        }
        return acc;
      },
      { nonGrowthIncentives: 0, nonGrowthSites: [], growthIncentives: 0, growthSites: [] }
    );

  const top5SpendRequired = operationalRebates
    .filter((obj) => obj.Spend2023 !== undefined)
    .map((x) => {
      return {
        ...x,
        spend_req: (x.Spend2023 | 0) - (x.Spend2022 | 0),
      };
    })
    .filter((x) => x.spend_req < 0)
    .sort((a, b) => b["spend_req"] - a["spend_req"])
    .slice(0, 5);

  const top5GrowthSites = operationalRebates
    .filter((obj) => obj.Spend2023 !== undefined)
    .map((item) => ({ ...item, amount: `$ ${Math.ceil(item["Incentive Amount"])}`, siteName: `${item?.city_2023}, ${item?.state_2023}` }))
    .filter((rebate) => rebate["Growth/De-growth"] === "Growth")
    .sort((a, b) => b["Incentive Amount"] - a["Incentive Amount"])
    .slice(0, 5);

  const groupedData = groupDataByYear(top5GrowthSites);

  useEffect(() => {
    const getYTDPurchase = async () => {
      setYtdPurchase({ loading: true });
      const data = await getYtdPurchaseAPI();
      if (data.code === 200) {
        setYtdPurchase({ loading: false, success: data.data });
      } else {
        setYtdPurchase({ loading: false, error: data.data.error });
      }
    };
    const getRebateTier = async () => {
      setRebateTier({ loading: true });

      const data = await getRebateTierAPI();
      if (data.code === 200) {
        setRebateTier({ loading: false, success: data.data });
      } else {
        setRebateTier({ loading: false, error: data.data.error });
      }
    };
    const getRemainingDays = async () => {
      setRemainingDays({ loading: true });

      const data = await getRemainingDaysAPI();
      if (data.code === 200) {
        setRemainingDays({ loading: false, success: data.data });
      } else {
        setRemainingDays({ loading: false, error: data.data.error });
      }
    };
    const getRebatableSites = async () => {
      setRebatableSites({ loading: true });

      const data = await getRebatableSitesAPI();

      if (data.code === 200) {
        setRebatableSites({ loading: false, success: data.data });
      } else {
        setRebatableSites({ loading: false, error: data.data.error });
      }
    };
    const getNonRebatableSites = async () => {
      setNonRebatableSites({ loading: true });

      const data = await getNonRebatableSitesAPI();
      if (data.code === 200) {
        setNonRebatableSites({ loading: false, success: data.data });
      } else {
        setNonRebatableSites({ loading: false, error: data.data.error });
      }
    };

    const getAllRebates = async () => {
      setAllRebateTiers({ loading: true });

      const data = await getAllRebatesTierAPI();
      if (data.code === 200) {
        setAllRebateTiers({ loading: false, success: data.data });
      } else {
        setAllRebateTiers({ loading: false, error: data.data.error });
      }
    };

    const getSitesGeneratingRebate = async () => {
      setSitesGrowth({ loading: true });

      const data = await getSitesGeneratingRebatesAPI();
      if (data.code === 200) {
        setSitesGrowth({ loading: false, success: data.data });
      } else {
        setSitesGrowth({ loading: false, error: data.data.error });
      }
    };

    const getAllRebatesTierRow = async () => {
      setSpendTierRow({ loading: true });

      const data = await getAllRebatesTierRowAPI(operationalRebatesData.totalSpend2023);
      // const data = await getAllRebatesTierRowAPI(100);
      if (data.code === 200) {
        setSpendTierRow({ loading: false, success: data.data });

        setOperationalRebatePercentage(
          percentageChange(Number((operationalRebatesData?.totalSpend2023 * data.data[0].tier_rebate) / 100) + Number(operationalRebatesData.totalIncentiveAmount), Number((operationalRebatesData?.totalSpend2022 * data.data[0].tier_rebate) / 100) + 1)
        );

        setAnnualIncentives([
          {
            year: "2022",
            type: "Rebate",
            value: Number((operationalRebatesData?.totalSpend2022 * data.data[0].tier_rebate) / 100),
          },
          {
            year: "2022",
            type: "Growth",
            value: 1,
          },
          {
            year: "2023",
            type: "Rebate",
            value: Number((operationalRebatesData?.totalSpend2023 * data.data[0].tier_rebate) / 100),
          },
          {
            year: "2023",
            type: "Growth",
            value: Number(operationalRebatesData.totalIncentiveAmount),
          },
        ]);
      } else {
        setSpendTierRow({ loading: false, error: data.data.error });
      }
    };

    getYTDPurchase();
    getRebateTier();
    getRemainingDays();
    getRebatableSites();
    getNonRebatableSites();
    getAllRebates();
    getAllRebatesTierRow();
    getSitesGeneratingRebate();
  }, []);

  const config = {
    data: annualIncentives,
    xField: "year",
    yField: "value",
    seriesField: "type",
    isGroup: "true",
    legend: {
      position: "bottom-left",
      offsetX: 0,
      marker: {
        symbol: "circle", // Set the legend icon shape to circle
      },
    },
    color: ["#3182ce", "#FB7D59"],
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 4], // Make the row lines dotted
          },
        },
      },
      label: {
        formatter: (value) => `$ ${new Intl.NumberFormat("en-US").format(value)}`,
      },
    },
    tooltip: {
      formatter: (datum) => ({
        name: datum.type,
        value: `$ ${Math.floor(datum.value)}`, // Round to 2 decimal places
      }),
    },
  };

  const config2 = {
    appendPadding: 10,
    data: [
      {
        type: "Growth Sites",
        // value: sitesGeneratingRebates.growthIncentives,
        value: sitesGrowth?.success?.filter((x) => x.growth > 0)?.length,
      },
      {
        type: "Non Growth Sites",
        // value: sitesGeneratingRebates.nonGrowthIncentives,
        value: sitesGrowth?.success?.filter((x) => x.growth < 0)?.length,
      },
    ],
    legend: {
      position: "bottom-left",
    },
    color: ["#3182ce", "#FB7D59"],

    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#888997",
          fontSize: "26px",
        },
        formatter: function formatter(e) {
          return `Total \n\n ${sitesGrowth?.success?.length} Sites`;
        },
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (event) => {
        history.push(`${VIEW_PO_COMPLIANCE_ROUTE}?type=${event.data.data.type === "Non Growth Sites" ? "non_growth" : "growth"}&sort=desc`);
      });
    },
  };

  const config3 = {
    data: top5SpendRequired?.map((x) => ({
      label: `${x.city_2023 || x.city_2022},${x.state_2023 || x.state_2022}`,
      // value: `${new Intl.NumberFormat("en-US").format(Math.abs(x["spend_req"] + 1))} $`,
      value: Math.abs(x["spend_req"] + 1),
    })),
    isGroup: false,
    xField: "value",
    yField: "label",
    marginRatio: 0,
    color: "#FB7D59",
    label: {
      position: "middle",
      color: "#FF0000", // Change legend color here
      offset: 20,
      style: {
        fontSize: 16, // Adjust the font size as needed
        color: "#FF0000", // Change legend color here
      },
      formatter: (legendValue) => {
        return `$ ${new Intl.NumberFormat("en-US").format(Math.abs(legendValue.value))}`;
      },
    },
    tooltip: {
      formatter: (v, data, index, col) => {
        return {
          name: "amount",
          value: `$${v?.value}`,
        };
      },
    },
    xAxis: false,
    responsive: true,
    meta: {
      label: {
        nice: true,
        adjustPosition: true,
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (event) => {
        history.push(`${VIEW_PO_COMPLIANCE_ROUTE}?search=${event.data.data.label.split(",")[0]}`);
      });
    },
  };

  const config4 = {
    data: [
      {
        title: "",
        ranges: allRebateTiers?.success ? allRebateTiers?.success?.map((x) => x.tier_max) : [33, 66, 99],
        measures: rebatableSites?.success ? [rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount] : [100],
        target:
          spendTierRow?.success && allRebateTiers?.success
            ? spendTierRow?.success?.length
              ? spendTierRow?.success[0]?.next_tier === "N/A"
                ? spendTierRow?.success[0]?.tier_max
                : allRebateTiers?.success[allRebateTiers?.success?.length - 1].tier_max
              : allRebateTiers?.success[0].tier_min
            : 100,
      },
    ],
    measureField: "measures",
    rangeField: "ranges",
    targetField: "target",
    xField: "title",
    color: {
      range: "#f0efff",
      measure: "#149BA1",
      target: "#149BA1",
    },
    xAxis: {
      line: null,
    },
    yAxis: {
      data: ["Tier 0", "Tier 1", "Tier 2", "Tier 3"],

      label: {
        formatter: (tierName, data, index) => {
          return (
            [
              {
                key: "$0M",
              },
              ...(allRebateTiers?.success
                ? allRebateTiers?.success?.map((tier) => ({
                    key: `$${amount.format(tier?.tier_min)}`,
                  }))
                : []),
            ][index]?.key || ``
          );
        },
      },
    },
    label: {
      target: false,

      style: {
        padding: 10, // Add padding
        fontSize: 18, // Adjust font siz
      },
    },
    legend: {
      custom: true,
      position: "bottom",
      items: allRebateTiers?.success
        ? [
            {
              value: "Tier 0",
              name: "Tier 0 ( $0M - $1M ) \n\n No Rebate",
              marker: {
                symbol: "line",
                style: {
                  // stroke: "#FFbcb8",
                  fill: "#3182ce",

                  r: 15,
                },
              },
            },
            ...allRebateTiers?.success?.map((tier) => ({
              value: `${tier?.tier_name} ( ${amount.format(tier?.tier_min)} - ${amount.format(tier?.tier_max)} ) \n\n ${tier?.tier_rebate}% Rebate`,
              name: `${tier?.tier_name} ( $${tier?.next_tier === "N/A" ? " >" + amount.format(tier?.tier_max) : amount.format(tier?.tier_min) + " - $" + amount.format(tier?.tier_max)} ) \n\n ${tier?.tier_rebate}% Rebate`,
              marker: {
                style: {
                  // stroke: colors[tier?.tier_name],
                  r: 15,
                  fill: "#3182ce",
                },
              },
            })),
          ]
        : [
            {
              value: "Tier 0",
              name: "Tier 0 \n No Rebate \n 0",
              marker: {
                symbol: "line",
                style: {
                  // stroke: "#FFbcb8",
                  r: 15,
                },
              },
            },
          ],
    },
  };
  const config5 = {
    data: groupedData,
    xField: "siteName",
    yField: "spend",
    colorField: "type",
    legend: false,
    // colorField: ["#3182ce", "#FB7D59"],
    // legend: {
    //   position: "bottom-left",
    //   offsetX: 0,
    //   marker: {
    //     symbol: "circle", // Set the legend icon shape to circle
    //   },
    // },
    stack: true,
    style: {
      stroke: "white", // Change the color of the separator
    },
    state: {
      active: { linkFill: "rgba(0,0,0,0.25)", stroke: "black", lineWidth: 0.5 },
      inactive: { opacity: 0.5 },
    },

    interaction: {
      elementHighlightByColor: {
        link: true,
      },
      tooltip: {
        render: (e, { title, items }, data) => {
          return (
            <div key={title}>
              <h4>{`Growth Incentive: $ ${Math.ceil(findByItem(groupedData, "siteName", title)["Incentive Amount"])}`}</h4>
              {items.map((item, index) => {
                const { name, value, color } = item;
                return (
                  <div>
                    <div style={{ margin: 0, display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <span
                          style={{
                            display: "inline-block",
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            backgroundColor: color,
                            marginRight: 6,
                          }}
                        ></span>
                        <span> Spend {index === 0 ? "2022" : "2023"}: </span>
                      </div>
                      <b style={{ marginLeft: "10px" }}>${value}</b>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        },
      },
    },

    axis: {
      y: {
        labelFormatter: (datum, index) => `$${datum}`,
      },
    },

    // data: groupedData,
    // xField: "siteName",
    // yField: "spend",
    // seriesField: "year",
    // isGroup: "true",
    // legend: {
    //   position: "bottom-left",
    //   offsetX: 0,
    //   marker: {
    //     symbol: "circle", // Set the legend icon shape to circle
    //   },
    // },
    // color: ["#FB7D59", "#3182ce"],
    // yAxis: {
    //   grid: {
    //     line: {
    //       style: {
    //         lineDash: [4, 4], // Make the row lines dotted
    //       },
    //     },
    //   },
    //   label: {
    //     formatter: (value) => `$ ${new Intl.NumberFormat("en-US").format(value)}`,
    //   },
    // },
    // tooltip: {
    //   render: (a, b, c) => {
    //     console.log(a, b, c);
    //     return <p>ok</p>;
    //   },
    // },
  };

  const config_ = {
    data: groupedData,
    xField: "siteName",
    yField: "spend",
    seriesField: "type",
    isGroup: "true",
    legend: {
      position: "bottom-left",
      offsetX: 0,
      marker: {
        symbol: "circle", // Set the legend icon shape to circle
      },
    },
    legend: false,
    color: ["#3182ce", "#FB7D59"],
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 4], // Make the row lines dotted
          },
        },
      },
      label: {
        formatter: (value) => `$ ${new Intl.NumberFormat("en-US").format(value)}`,
      },
    },
    tooltip: {
      customContent: (title, data) => {
        return (
          <div>
            {title && <h4>{`Growth Incentive: $ ${Math.ceil(findByItem(groupedData, "siteName", title)["Incentive Amount"])}`}</h4>}
            {data.map((item, index) => {
              const { color } = item;
              return (
                <div>
                  <div style={{ margin: 0, display: "flex", justifyContent: "space-between", padding: "12px 0px" }}>
                    <div>
                      <span
                        style={{
                          display: "inline-block",
                          width: 6,
                          height: 6,
                          borderRadius: "50%",
                          backgroundColor: color,
                          marginRight: 6,
                        }}
                      ></span>
                      <span> Spend {item?.name === "A" ? "2022" : "2023"}: </span>
                    </div>
                    <b style={{ marginLeft: "10px" }}>${item?.value}</b>
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
    onReady: (plot) => {
      plot.on("element:click", (event) => {
        history.push(`${VIEW_PO_COMPLIANCE_ROUTE}?search=${event?.data?.data?.city}`);
      });
    },
  };

  return (
    <>
      {/* Header */}
      <Row className="content-header">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>
            Rebate Scorecard, <b>Year 2023</b>
          </h2>
        </Col>
      </Row>
      {/* Form for Vendor Selection */}
      {/* <Form id="form" form={form} className="form-vertical-t2" layout="vertical" validateTrigger="onBlur">
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form-vertical-t2 form-horizontal-t2 form-layout-3">
              {" "}
              <Row className="">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="vendor" label="Vendor">
                    <VendorDropdown disabled={true} label="Vendor" vendor={vendor} id={null} defaultValue={"Supply Force"} getValue={(val) => console.log(val)} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form> */}
      {/* KPIS Cards */}
      {/* <Row justify="space-between"> */}
      <Row gutter={[24, 24]} className="rowFlexCustom space-between">
        <Col className="f-width">
          <div className="p-18 card-border bg-white border-radius-10 cardWidth">
            {ytdPurchase?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <p className="card-heading">
                  <img src={DollarSign} alt="dollar" width={25} height={25} />
                  {new Intl.NumberFormat("en-US").format(ytdPurchase?.success?.total_amount_2023)}
                </p>
                <p className="desc-between">YTD Purchases</p>
                <p>
                  <img src={Number(ytdPurchase?.success?.percentage_increase) > 0 ? ArrowUp : ArrowDown} alt="dollar" />{" "}
                  <span className="desc-bottom">
                    {" "}
                    {Number(ytdPurchase?.success?.percentage_increase) > 0 ? "Up" : "Down"}
                    &nbsp; {ytdPurchase?.success?.percentage_increase} % this year
                  </span>
                </p>
              </>
            )}
          </div>
        </Col>
        <Col className="f-width">
          <div className="p-18 card-border bg-white border-radius-10 p-18 cardWidth">
            {spendTierRow?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <p className="card-heading">
                  <img src={DollarSign} alt="dollar" width={25} height={25} />
                  {spendTierRow?.success
                    ? spendTierRow?.success?.length
                      ? new Intl.NumberFormat("en-US").format(Math.round((operationalRebatesData?.totalSpend2023 * spendTierRow?.success[0].tier_rebate) / 100 + operationalRebatesData.totalIncentiveAmount))
                      : new Intl.NumberFormat("en-US").format(Math.round((operationalRebatesData?.totalSpend2023 * 0) / 100 + operationalRebatesData.totalIncentiveAmount))
                    : 0}
                </p>
                <p className="desc-between">Operational Rebates</p>
                <p>
                  {/* N/A */}
                  <img src={Number(operationalRebatePercentage) > 0 ? ArrowUp : ArrowDown} alt="dollar" />{" "}
                  <span className="desc-bottom">
                    {" "}
                    {Number(operationalRebatePercentage) > 0 ? "Up" : "Down"}
                    &nbsp; {operationalRebatePercentage.toFixed(2)} % this year
                  </span>
                </p>
              </>
            )}
          </div>
        </Col>
        <Col className="f-width">
          <div className="p-18 card-border bg-white border-radius-10 cardWidth">
            {spendTierRow?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <p className="card-heading">
                  <img src={DollarSign} alt="dollar" width={25} height={25} />
                  {spendTierRow?.success ? (spendTierRow?.success?.length ? `${new Intl.NumberFormat("en-US").format(Math.round((operationalRebatesData?.totalSpend2023 * spendTierRow?.success[0].tier_rebate) / 100))}` : 0) : 0}
                </p>
                <p className="desc-between font-18">Rebate</p>
                <br />

                <p className="card-heading">
                  <img src={DollarSign} alt="dollar" width={25} height={25} />
                  {spendTierRow?.success ? (spendTierRow?.success?.length ? `${new Intl.NumberFormat("en-US").format(Math.round(operationalRebatesData.totalIncentiveAmount))}` : 0) : 0}
                </p>
                <p className="desc-between font-18">Growth</p>
              </>
            )}
          </div>
        </Col>
        <Col className="f-width">
          <div className="p-18 card-border bg-white border-radius-10 cardWidth">
            {spendTierRow?.loading || allRebateTiers?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                {/* <small className="desc-between super-item">Current Rebate Tier</small> */}
                <div className="flex">
                  <p className="card-heading">{spendTierRow?.success?.length ? spendTierRow?.success[0]?.tier_name : "Tier 0"} &nbsp;</p>
                  <small className="desc-between">
                    {" "}
                    {spendTierRow?.success?.length
                      ? "( $" + amount.format(spendTierRow?.success[0]?.tier_min) + " - $" + amount.format(spendTierRow?.success[0]?.tier_max) + ")" + " " + spendTierRow?.success[0]?.tier_rebate + " % "
                      : "( $0M - $1M )" + " 0%"}
                  </small>
                </div>
                <small className="desc-between">Current Rebate Tier</small>
                <div className="flex gap-50 mt-20">
                  <div>
                    <p className="desc-between">Next Tier</p>
                    <p className="desc-bottom">
                      {spendTierRow?.success?.length
                        ? spendTierRow?.success[0]?.next_tier +
                          " ( $" +
                          (amount.format(allRebateTiers?.success?.find((x) => x.tier_name === spendTierRow?.success[0]?.next_tier).tier_min) +
                            " - $" +
                            amount.format(allRebateTiers?.success?.find((x) => x.tier_name === spendTierRow?.success[0]?.next_tier).tier_max)) +
                          "  ) " +
                          allRebateTiers?.success?.find((x) => x.tier_name === spendTierRow?.success[0]?.next_tier).tier_rebate +
                          " % "
                        : allRebateTiers?.success[0].tier_name +
                          " ( " +
                          (amount.format(allRebateTiers?.success[0].tier_min) + "- " + amount.format(allRebateTiers?.success[0].tier_max)) +
                          " ) " +
                          amount.format(allRebateTiers?.success[0].tier_rebate) +
                          " %"}
                    </p>
                  </div>
                  <div>
                    <p className="desc-between">Needed</p>
                    <p className="danger">
                      ${" "}
                      {spendTierRow?.success?.length
                        ? new Intl.NumberFormat("en-US").format(Math.round(spendTierRow?.success[0]?.tier_max + 1 - rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount))
                        : new Intl.NumberFormat("en-US").format(Math.round(allRebateTiers?.success[0]?.tier_min + 1 - rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount))}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
        {/* <Col span={6} className="card-border bg-white border-radius-10">
          <div className="p-18">
            {remainingDays?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <p className="card-heading">{Math.abs(remainingDays?.success[0]?.remaining_days)}</p>
                <p className={`${remainingDays?.success[0]?.remaining_days > 0 ? "desc-between" : "desc-between color-orange"}`}>Days {remainingDays?.success[0]?.remaining_days > 0 ? "remaining" : "overdue"} </p>
                <p className="desc-bottom">on Expiring contract</p>
              </>
            )}
          </div>
        </Col> */}
      </Row>
      {/* YTD Spend Breakdown */}
      {/* Annual Incentives */}
      <Row gutter={[16, 16]} className="space-between">
        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18 customscroll">
            {rebatableSites?.loading || nonRebatableSites?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  YTD Spend Breakdown <img src={Information} alt="information-icon" />
                </h1>
                {/* Upper Part */}
                <div className="flex flex-between">
                  <div>
                    <div className="ytd-breakdown-legend">
                      <p
                        className={`spend-rebates rebates
                    ${rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount > nonRebatableSites?.success?.nonRebatableSitesTotalResult[0]?.grand_total_amount ? "max" : "min"}
                    `}
                      >
                        <img src={DollarSign_white} alt="dollar" /> {rebatableSites?.success && new Intl.NumberFormat("en-US").format(rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount)}
                      </p>
                      <h3 className="desc-between">YTD Rebatable Spend</h3>
                    </div>

                    <div className="ytd-breakdown-legend">
                      <p
                        className={`spend-rebates non-rebates
                    
                    ${nonRebatableSites?.success?.nonRebatableSitesTotalResult[0]?.grand_total_amount > rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount ? "max" : "min"}
                    
                    `}
                      >
                        <img src={DollarSign_white} alt="dollar" /> {nonRebatableSites?.success && new Intl.NumberFormat("en-US").format(nonRebatableSites?.success?.nonRebatableSitesTotalResult[0]?.grand_total_amount)}
                      </p>
                      <h3 className="desc-between">YTD Non-Rebatable Spend</h3>
                    </div>
                  </div>
                  {/* <div>
                    <div className="bg-gray">
                      <div>
                        <p className="rebates-title">YTD Rebatable Spend</p>
                        <p className="desc-between">
                          <img src={DollarSign} alt="dollar" />
                          {rebatableSites?.success && new Intl.NumberFormat("en-US").format(rebatableSites?.success?.rebatableSitesTotalResult[0]?.grand_total_amount)}
                        </p>
                      </div>
                      <div>
                        <p className="rebates-title">YTD Non-Rebatable Spend</p>
                        <p className="desc-between">
                          <img src={DollarSign} alt="dollar" />
                          {nonRebatableSites?.success && new Intl.NumberFormat("en-US").format(nonRebatableSites?.success?.nonRebatableSitesTotalResult[0]?.grand_total_amount)}
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* Lower Section */}
                {/* Sites Rebatable */}
                <div className="flex flex-between section pl-25 d-blockMed">
                  <div>
                    <h3 className="desc-between-ytd">Top Sites Rebatable Spend</h3>
                    <div className="flex flex-between">
                      <div>
                        <p className="rebates-title">Site</p>
                        {rebatableSites?.success &&
                          rebatableSites?.success?.topRebatableSitesResult?.map((res, index) => (
                            <p key={index}>
                              {res.city}, {res.state}
                            </p>
                          ))}
                        <p className="view-all">
                          <Link to={`${VIEW_PO_COMPLIANCE_ROUTE}?type=rebatable_spend&sort=desc`}>View All</Link>
                        </p>
                      </div>
                      <div>
                        <p className="rebates-title">Amount</p>
                        {rebatableSites?.success &&
                          rebatableSites?.success?.topRebatableSitesResult?.map((res, index) => (
                            <p key={index} className="color-black">
                              $ {new Intl.NumberFormat("en-US").format(res.total_amount)}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="desc-between-ytd">Top Sites Non-Rebatable Spend</h3>
                    <div className="flex flex-between">
                      <div>
                        <p className="rebates-title">Site</p>
                        {nonRebatableSites?.success &&
                          nonRebatableSites?.success.topNonRebatableSitesResult?.map((res, index) => (
                            <p key={index}>
                              {res.city}, {res.state}
                            </p>
                          ))}

                        <p className="view-all">
                          <Link to={`${VIEW_PO_COMPLIANCE_ROUTE}?type=non_rebatable_spend&sort=desc`}>View All</Link>
                        </p>
                      </div>
                      <div>
                        <p className="rebates-title">Amount</p>
                        {nonRebatableSites?.success &&
                          nonRebatableSites?.success.topNonRebatableSitesResult?.map((res, index) => (
                            <p key={index} className="color-black">
                              $ {new Intl.NumberFormat("en-US").format(res.total_amount)}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18">
            {ytdPurchase?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  Annual Incentives <img src={Information} alt="information-icon" />
                </h1>

                <Column {...config} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {/* Sites Generating Rebates */}

        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18">
            {ytdPurchase?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  Sites Generating Growth Incentives <img src={Information} alt="information-icon" />
                </h1>
                <Pie {...config2} />
              </>
            )}
          </div>
        </Col>
        {/* Rebate Tier */}

        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18">
            <h1 className="mb-20 desc-between-ytd">
              Rebate Tier <img src={Information} alt="information-icon" />{" "}
            </h1>
          </div>
          <div className="p-18 centerObject">
            {/* {allRebateTiers?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  Rebate Tier <img src={Information} alt="information-icon" />
                </h1>
                <Bullet {...config4} />
              </>
            )} */}

            {allRebateTiers?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <RebateTier rebateTiers={allRebateTiers} rebatableAmount={rebatableSites} currentRebate={spendTierRow?.success?.length ? spendTierRow?.success[0]?.tier_name : "Tier 0"} />
              </>
            )}
          </div>
        </Col>

        {/* Amount to achieve Growth incentive */}

        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18 pr-50">
            {ytdPurchase?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  Amount to achieve Growth Incentive <img src={Information} alt="information-icon" />
                </h1>

                <Bar {...config3} />
              </>
            )}
          </div>
        </Col>

        {/* Top 5 Growth Sites */}

        <Col xl={12} lg={24} sm={24} md={24} xs={24} className="card-border bg-white border-radius-10 box100">
          <div className="p-18 pr-50">
            {ytdPurchase?.loading ? (
              <div style={{ fontSize: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            ) : (
              <>
                <h1 className="mb-20 desc-between-ytd">
                  Top 5 Growth Incentive generating Sites <img src={Information} alt="information-icon" />
                </h1>

                {/* <ColumnStacked {...config5} /> */}
                <Column {...config_} />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Index;
