import { login, registerUser } from "../../Services/Auth/auth";
import { saveToLocal } from "../../utils/cache";
import { LOCAL_STORAGE_KEYS, ROLES_GENERAL, ROLES_REPORT_VIEW } from "../../utils/constants";
import {
  HIDE_LOADER,
  SHOW_LOADER,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
} from "../Types";
import { actionDispatch, triggerNotification } from "./";
import history from "../../History/history";
import { RESET_PASSWORD_SUCCESS, SIGNIN_FAILURE_MSG,RESET_PASSWORD_FAILED } from "../../Constants/validation";
import {
  forgotUserPassword,
  resetUserPassword,
  requestDemoApp
} from "../../Services/Auth/auth";
import { isAuthorized } from "../../Services/Auth/auth";
import { SAMPLE_DASHBOARD_ROUTE } from "../../Constants/routeNames";

export const signIn = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  dispatch(actionDispatch(HIDE_NOTIFICATION));
  try {
    const res = await login(data);
    if (res.code === 200) {
      if (!res.data.isDocsUploaded) {
        saveToLocal(res.data.jwt, LOCAL_STORAGE_KEYS.TOKEN, false, true);
        saveToLocal(res.data, LOCAL_STORAGE_KEYS.USER, true, true);
        saveToLocal(res.data.roles, LOCAL_STORAGE_KEYS.ROLES, true, true);
        dispatch(
          actionDispatch(SIGNIN_SUCCESS, {
            user: res.data,
            jwt: res.data.jwt,
            roles: res.data.roles,
          })
        );
        if (isAuthorized(ROLES_GENERAL, res.data.roles)) history.push("/");
        if (isAuthorized(ROLES_REPORT_VIEW, res.data.roles)) history.push("/reports/view");
      } else {
        history.push(SAMPLE_DASHBOARD_ROUTE);
      }
    } else {
      dispatch(actionDispatch(SIGNIN_FAILURE));
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: res.message,
          open: true,
          type: "error",
        })
      );
    }
    dispatch(actionDispatch(HIDE_LOADER));
  } catch (ex) {
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data ? ex.response.data.message : SIGNIN_FAILURE_MSG,
        open: true,
        type: "error",
      })
    );
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await forgotUserPassword(data);
    dispatch(actionDispatch(HIDE_LOADER));
    if (res && res.success) {
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: `${RESET_PASSWORD_SUCCESS}`,
          open: true,
          type: "success",
        })
      );
    } else {
      dispatch(
        actionDispatch(SHOW_NOTIFICATION, {
          message: res.msg
            ? res.msg
            : RESET_PASSWORD_FAILED,
          open: true,
          type: "error",
        })
      );
    }
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message: ex.response && ex.response.data 
          ?  ex.response.data.message
          : RESET_PASSWORD_FAILED,
        open: true,
        type: "error",
      })
    );
  }
};

export const resetPassword = (data, token) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await resetUserPassword(data, { tk: token });
    dispatch(actionDispatch(HIDE_LOADER));
      dispatch(triggerNotification("success", res.message))
    history.push("/");
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("error", ex.message));
  }
};

export const requestAppDemo = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await requestDemoApp(data);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("success", res.message))
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("error", ex.message));
  }
};

export const registerNewUser = (data) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    const res = await registerUser(data);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("success", res.message))
    return res;
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(triggerNotification("error", ex.message || ex.response.data?.message || "An unknown error occurred."));
  }
};
