import React, { useEffect, useState } from "react";
import { Row, Col, Upload, Button, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actionDispatch, triggerNotification } from "../../../actions/Creators";
import { HIDE_NOTIFICATION } from "../../../actions/Types";
import Notification from "../../../Common/Notification";

const Uploader = ({ files, setFiles, type, accept, customRequest, uploadURL = "/upload", format = "pdf", pricingData, uploaderType, setLoading, loading, setError, vendor, isDisabled }) => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.commonReducer.notification);

  const [percent, setPercent] = useState(5);

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        if (percent < 95) {
          setPercent((prevPercent) => prevPercent + 3);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [percent, loading]);

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    // action: uploadURL,
    ...(customRequest && {
      customRequest: (data) => customRequest(data?.file),
    }),
    accept,
    onChange({ file, fileList }) {
      
      if (file.status === "uploading") {
        setPercent(0);
        // setLoading && setLoading(true);
      }
      if (file.status === "done") {
        setLoading && setLoading(false);
        setPercent(100);
        setPercent(0);
      }
      if (file.status === "error") {
        setLoading && setLoading(false);
        setError && setError(true);
        dispatch(triggerNotification("error", "Something went wrong! Please try again later."));
      }
      if (file.status !== "uploading") {
        setFiles(fileList);
      }
      // checking for the dates before upload and clear files
      if (uploaderType === "New pricing Sheet" && Object.keys(pricingData)?.length < 2) {
        setFiles([]);
      }
    },
    showUploadList: false,
    fileList: files,
    // checking for the dates before upload
    beforeUpload: (file) => false
  };
  return (
    <React.Fragment>
      <Row justify="center" className="upload-wrapper">
        <Col xl={24}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <span class="icon-upload-contract"></span>
            </p>
            <p className="label-upload">Drag and drop files. </p>
            <p className="label-or">or</p>

            <Button className="btn-upload" disabled={loading && isDisabled}>
                Upload
            </Button>
          </Dragger>
        </Col>
      </Row>
      <Row justify="right" className="uplaod-msg-wrapper">
        <Col xl={24}>
          <span>* Only {format} files supported</span>
        </Col>
      </Row>

      {setLoading && loading && (
        <div>
          <Progress percent={percent} />
          <h4 className="loading-text">Your data is uploading, please wait..</h4>
        </div>
      )}

      {notification.open && notification.message ? (
        <Notification
          msg={notification.message}
          type={notification.type}
          clearMsg={() => {
            dispatch(actionDispatch(HIDE_NOTIFICATION));
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Uploader;
