import { LOGIN_ROUTE } from "../../Constants/routeNames";
import { useHistory } from "react-router-dom";
import { DOCUMENTS_ADDED_SUCCESS, UNPERFORMED_SAVE_ACTION } from "../../Constants/validation";
import { createDocsFromUploadedFiles, uploadNewUserDocsAPI } from "../../Services/Documents/document";
import { uploadToS3 } from "../../utils/s3Upload";
import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION } from "../Types";
import { actionDispatch } from "./actionDispatcher";
import history from "../../History/history";

export const uploadNewUserDocsCreator = (data, userId) => async (dispatch) => {
  dispatch(actionDispatch(SHOW_LOADER, { loader: true }));
  try {
    //create docs and get signedUrl in response
    const res = await uploadNewUserDocsAPI({ data, user_id: userId });
    const temp = [];
    //Loop over array and upload each file on url
    res.data.forEach(async (doc, i) => {
      await uploadToS3(doc.signedUrl, data[i].originFileObj);
      temp.push({
        user_id: userId,
        type: "new_user",
        key: doc.file_key,
        name: data[i].name,
      });
      //creating docs after successfully uploaded files
      if (temp.length == res.data.length) {
        const uploadRes = await createDocsFromUploadedFiles(temp);
        if (uploadRes.success) {
          dispatch(actionDispatch(HIDE_LOADER));
          history.push(`${LOGIN_ROUTE}`);
        }
        dispatch(
          actionDispatch(SHOW_NOTIFICATION, {
            message: DOCUMENTS_ADDED_SUCCESS,
            open: true,
            type: "success",
          })
        );
      }
    });
    //Hit Document API
  } catch (ex) {
    console.log(ex);
    dispatch(actionDispatch(HIDE_LOADER));
    dispatch(
      actionDispatch(SHOW_NOTIFICATION, {
        message:
          ex.response &&
            ex.response.data &&
            typeof ex.response.data.message == "string"
            ? ex.response.data.message
            : UNPERFORMED_SAVE_ACTION,
        open: true,
        type: "error",
      })
    );
  }
};