import { Menu, Layout, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AppLogo from "../assets/images/trupacta-logo-white.png";
import AppLogoDark from "../assets/images/app-logo-white.png";
import AppLogoCollapse from "../assets/images/app-logo-collapse.png";
import reportTemplates from "../Constants/reportTemplates";
import { setRedirectStatus } from "../actions/Creators/pagination";
import { getFromLocal } from "../utils/cache";
import { LOCAL_STORAGE_KEYS } from "../utils/constants";

import { DASHBOARD_ROUTE, VIEW_CONTRACTS_ROUTE, VIEW_INVOICES_ROUTE, VIEW_PO_ROUTE, USER_PROFILE_ROUTE, VIEW_REPORTS_ROUTE, VIEW_REBATE_SCORECARD, VIEW_SKU_ANALYTICS, VIEW_PO_COMPLIANCE_ROUTE, DATA_UPLOAD } from "../Constants/routeNames";
import { ROLES_CONTRACT_VIEW, ROLES_INVOICE_VIEW, ROLES_PO_VIEW, ROLES_INVOICE_MANAGER, ROLES_REPORT_VIEW, ROLES_GENERAL, ROLES_DISCREPANCY_APPROVER_VIEW } from "../utils/constants";
import Rebate_scorecard from "../assets/images/Rebate_Scorecard.svg";
import SKU_Analytics from "../assets/images/SKU_Analytics.svg";
import PO_COMPLIANCE from "../assets/images/po-compliance.svg";
import UPLOADER from "../assets/images/upload-file.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

const menuItems = [
  {
    title: "Dashboard",
    path: DASHBOARD_ROUTE,
    icon: <span className="menu-item-font-icon icon-dashboard"></span>,
    label: <span className="menu-item-label">Dashboard</span>,
    permissions: ROLES_GENERAL,
  },
  {
    title: "Data Products",
    submenu: [
      {
        title: "Data Upload",
        path: DATA_UPLOAD,
        icon: (
          <span className="menu-item-font-icon">
            <img src={UPLOADER} height={"22px"} />
          </span>
        ),
        label: <span className="menu-item-label">Data Upload</span>,
        public: false,
      },
      {
        title: "Contracts",
        path: VIEW_CONTRACTS_ROUTE,
        icon: <span className="menu-item-font-icon icon-icon-contract"></span>,
        label: <span className="menu-item-label">Contracts</span>,
        permissions: ROLES_CONTRACT_VIEW,
      },
      {
        title: "Purchase Orders",
        path: VIEW_PO_ROUTE,
        icon: <span className="menu-item-font-icon icon-purchase-order"></span>,
        label: <span className="menu-item-label">Purchase Orders</span>,
        public: false,
        permissions: ROLES_PO_VIEW,
      },
      {
        title: "Invoices",
        path: VIEW_INVOICES_ROUTE,
        icon: <span className="menu-item-font-icon icon-file-invoice-dollar"></span>,
        label: <span className="menu-item-label">Invoices</span>,
        public: false,
        permissions: [...ROLES_INVOICE_VIEW, ...ROLES_INVOICE_MANAGER],
      },
    ],
    public: false,
  },
  {
    title: "Business Insights",
    submenu: [
      {
        title: "Site Compliance",
        path: VIEW_PO_COMPLIANCE_ROUTE,
        icon: (
          <span>
            <img src={PO_COMPLIANCE} />
          </span>
        ),
        label: <span className="menu-item-label">Site Compliance</span>,
        public: false,
        permissions: ROLES_PO_VIEW, // ASK SUBUH
      },
      {
        title: "Rebate Scorecard",
        path: VIEW_REBATE_SCORECARD,
        icon: (
          <span className="menu-item-font-icon icon-rebate-scorecard">
            <img src={Rebate_scorecard} />
          </span>
        ),
        label: <span className="menu-item-label">Rebate Scorecard</span>,
        permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_GENERAL],
      },
      // {
      //   title: "SKU Analytics",
      //   path: VIEW_SKU_ANALYTICS,
      //   icon: (
      //     <span className="menu-item-font-icon icon-sku_analytics">
      //       <img src={SKU_Analytics} />
      //     </span>
      //   ),
      //   label: <span className="menu-item-label">SKU Analytics</span>,
      //   permissions: [...ROLES_REPORT_VIEW, ...ROLES_DISCREPANCY_APPROVER_VIEW, ...ROLES_GENERAL],
      // }
    ],
    public: false,
  },
  {
    title: "Settings",
    path: USER_PROFILE_ROUTE,
    icon: <span className="menu-item-font-icon icon-setting"></span>,
    label: <span className="menu-item-label">Settings</span>,
    public: true,
  },
];

const Sidebar = ({ collapsed }) => {
  const dispatch = useDispatch();
  const rolesArr = useSelector((state) => state.auth.roles);
  const myRoles = rolesArr.length ? rolesArr.map((roles) => roles.role.name) : [];
  const location = useLocation();
  const { pathname } = location;
  const setToDefaultPagination = () => {
    dispatch(setRedirectStatus(false, "contract"));
    dispatch(setRedirectStatus(false, "invoice"));
    dispatch(setRedirectStatus(false, "po"));
  };

  // Define the keys of the submenus to be open by default
  const defaultOpenKeys = menuItems.reduce((acc, menuItem, index) => {
    if (menuItem.submenu) {
      return [...acc, `sub${index}`];
    }
    return acc;
  }, []);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: "auto" }} className="tp_sidebar_main" width="255" collapsedWidth="70">
      {!collapsed ? (
        <div className={`side-logo ${getFromLocal(LOCAL_STORAGE_KEYS.THEME, false) === "dark-theme" ? 'dark' : 'light'}`} >
          <Image src={getFromLocal(LOCAL_STORAGE_KEYS.THEME, false) === "dark-theme" ? AppLogoDark : AppLogo} alt="Smart Contract" preview={false} />
        </div>
      ) : (
        <div className="collapse-side-logo">
          <Image src={AppLogoCollapse} alt="Smart Contract" preview={false} />
        </div>
      )}
      <Menu selectedKeys={[pathname.split("/")[1] ? pathname.split("/")[1] : "0"]} defaultSelectedKeys={["0"]} defaultOpenKeys={defaultOpenKeys} className="primary-nav" mode="inline">
        {menuItems.map((menuItem, index) => {
          if (menuItem.public) {
            return !menuItem.submenu ? (
              <Menu.Item key={menuItem.path.split("/")[1] || "0"}>
                <Link to={menuItem.path}>
                  {menuItem.icon} {menuItem.label}
                </Link>
              </Menu.Item>
            ) : (
              <SubMenu
                key={`sub${index}`}
                className="primary-nav"
                title={
                  <span>
                    <span style={{ fontSize: "20px" }} className="menu-item-font-icon icon-dashboard"></span>
                    <span style={{ padding: "0 0 0 1.35rem", fontSize: "15px" }}>{menuItem.title} </span>
                  </span>
                }
              >
                {menuItem?.submenu?.map((subMenuItem, subIndex) => (
                  <Menu.Item key={subMenuItem.path.split("/")[1] || "0"}>
                    <Link to={subMenuItem.path}>
                      {subMenuItem.icon} {subMenuItem.label}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            return myRoles.some((role) => menuItem?.permissions?.includes(role)) ? (
              <Menu.Item key={menuItem.path.split("/")[1] || "0"} onClick={setToDefaultPagination}>
                <Link to={menuItem.path}>
                  {menuItem.icon} {menuItem.label}
                </Link>
              </Menu.Item>
            ) : (
              <SubMenu
                key={`sub${index}`}
                className="primary-nav-2  "
                title={
                  <span>
                    <span style={{ fontSize: "20px" }} className="menu-item-font-icon icon-dashboard"></span>
                    <span style={{ padding: "0 0 0 1.35rem", fontSize: "15px" }}>{menuItem.title} </span>
                  </span>
                }
              >
                {menuItem?.submenu?.map((subMenuItem, subIndex) => (
                  <Menu.Item key={subMenuItem.path.split("/")[1] || "0"}>
                    <Link to={subMenuItem.path}>
                      {subMenuItem.icon}
                      {subMenuItem.label}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
